import {Place, Element, Type} from '@wix/ambassador-seating-v1-seating-plan/types'
import {WIX_EVENTS_TICKET_DEFINITION_FQDN} from '@wix/wix-events-commons-statics'
import React, {useState, useCallback} from 'react'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Modal} from '../modal'
import {getSelectedTicketQuantity} from '../../../selectors/selected-tickets'
import {getSeatWithTicketInfo} from '../../../selectors/seating'
import {SelectedPlace} from '../../../types/seating'
import {Loader} from './loader'
import {Mobile} from './mobile'
import {Desktop} from './desktop'
import {classes} from './seating-plan.st.css'
import {SeatingPlanProps} from '.'

export const SeatingPlan = ({
  plan,
  tickets,
  selectTicket,
  unselectTicket,
  selectedTickets,
  checkout,
  loading,
  event,
  selectedPlaces,
  selectSeat,
  unselectSeat,
}: SeatingPlanProps) => {
  const {t} = useTranslation()
  const {isMobile: mobile} = useEnvironment()
  const [selectedPlace, setSelectedPlace] = useState<SelectedPlace>(null)
  const desktop = !mobile

  const handleAddClick = useCallback(() => {
    const ticketId = selectedPlace.ticketId
    selectSeat(selectedPlace)
    selectTicket({
      ticketId,
      count: getSelectedTicketQuantity(selectedTickets, ticketId) + 1,
      seatId: selectedPlace.id,
    })
    setSelectedPlace(null)
  }, [selectSeat, selectTicket, selectedPlace, selectedTickets])

  React.useEffect(() => {
    if (desktop && selectedPlace) {
      handleAddClick()
    }
  }, [selectedPlace, handleAddClick, desktop])

  const handleCheckout = () => checkout()

  const handleRemove = (id: string, ticketId: string) => {
    unselectTicket({ticketId, count: getSelectedTicketQuantity(selectedTickets, ticketId) - 1, seatId: id})
    unselectSeat(id)
  }

  const onSeatClick = (seat: Place, categoryId: number, definition: Element) => {
    const {externalId} = plan.categories.find(category => category.id === categoryId)
    const ticketDefinition = tickets.find(
      ticketDef => ticketDef.id === externalId.replace(WIX_EVENTS_TICKET_DEFINITION_FQDN, ''),
    )
    const area = seat.elementType === Type.AREA

    if (!area && selectedPlaces.find(selected => selected.id === seat.id)) {
      return handleRemove(seat.id, ticketDefinition.id)
    }
    setSelectedPlace(getSeatWithTicketInfo({plan, event, seat, categoryId, t, tickets}))
  }

  return (
    <Modal
      className={desktop ? classes.desktopModal : undefined}
      closeButtonAsButton={desktop}
      dataHook={DH.SEATINGS_MODAL}
      title={mobile ? t('seatingMapModal_title') : undefined}
      withoutPadding={desktop}
    >
      {loading ? <Loader /> : null}
      {mobile ? (
        <Mobile
          selectedPlace={selectedPlace}
          selectedPlaces={selectedPlaces}
          plan={plan}
          onRemoveClick={handleRemove}
          onCheckoutClick={handleCheckout}
          onSeatClick={onSeatClick}
          onCloseClick={() => setSelectedPlace(null)}
          onAddClick={handleAddClick}
        />
      ) : (
        <Desktop
          selectedPlace={selectedPlace}
          selectedPlaces={selectedPlaces}
          plan={plan}
          onSeatClick={onSeatClick}
          onRemoveClick={handleRemove}
          onCheckoutClick={handleCheckout}
        />
      )}
    </Modal>
  )
}
