import * as React from 'react'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {getCheckoutSummary, getPaidPlanPercentDiscount, getTaxLabel} from '@wix/wix-events-commons-statics'
import {Text} from 'wix-ui-tpa/Text'
import classNames from 'classnames'
import {classes} from './inner-summary.st.css'
import {InnerSummaryProps, SummaryItemProps} from '.'

export const InnerSummary = ({invoice, selectedTickets, withoutBreakdown}: InnerSummaryProps) => {
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()
  const {subtotal, tax, addedFee, total, paidPlanDiscount} = getCheckoutSummary(invoice)

  return withoutBreakdown ? (
    <SummaryItem
      className={classes.total}
      label={t('seatings_selectedTickets', {count: selectedTickets})}
      value={total}
    />
  ) : (
    <div className={classNames(classes.container, isMobile ? classes.mobileContainer : undefined)}>
      {subtotal ? (
        <div>
          <SummaryItem label={t('seatings_selectedTickets', {count: selectedTickets})} value={subtotal} />
          <SummaryItem
            label={t('ticketsThankYou.paidPlan', {
              discount: getPaidPlanPercentDiscount(invoice),
            })}
            value={paidPlanDiscount}
          />
          <SummaryItem label={getTaxLabel(invoice)} value={tax} />
          <SummaryItem label={t('checkout_serviceFee')} value={addedFee} />
        </div>
      ) : null}
      <SummaryItem className={classes.total} label={t('mobile.ticketsPicker.total')} value={total} />
    </div>
  )
}

const SummaryItem = ({label, value, className}: SummaryItemProps) =>
  value ? (
    <div className={classNames(classes.summaryItem, className)} tabIndex={0}>
      <Text className={classes.label}>{label}</Text>
      <Text className={classes.value}>{value}</Text>
    </div>
  ) : null
