import * as React from 'react'
import {StatesButton, BUTTON_STATES} from 'wix-ui-tpa/StatesButton'
import {IconButton} from 'wix-ui-tpa/IconButton'
import CloseSmall from 'wix-ui-icons-common/on-stage/CloseSmall'
import DeleteSmall from 'wix-ui-icons-common/on-stage/DeleteSmall'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Checkbox} from 'wix-ui-tpa/Checkbox'
import {Text} from 'wix-ui-tpa/Text'
import {Type} from '@wix/ambassador-seating-v1-seating-plan/types'
import {LabelWithValue} from '../../../common/label-with-value'
import {classes} from './ticket-info.st.css'
import {TicketInfoProps} from '.'

export const TicketInfo = ({
  selectedSeat,
  withCheckbox,
  selected,
  onAddClick,
  onCloseClick,
  onRemoveClick,
}: TicketInfoProps) => {
  const {t} = useTranslation()
  const [successButtonState, setSuccessButtonState] = React.useState(BUTTON_STATES.IDLE)

  if (!selectedSeat) {
    return null
  }

  const {elementType, sector, seat, row, ticketName, ticketPrice, fees} = selectedSeat
  const area = elementType === Type.AREA

  const handleStatesButtonClick = async () => {
    setTimeout(() => {
      onAddClick()
    }, 800)
    setSuccessButtonState(BUTTON_STATES.SUCCESS)
  }

  return (
    <div className={classes.container} aria-hidden={true}>
      {withCheckbox ? <Checkbox disabled className={classes.checkbox} checked={selected} onChange={undefined} /> : null}
      {onCloseClick ? (
        <IconButton className={classes.headerButton} icon={<CloseSmall />} onClick={onCloseClick} />
      ) : null}
      {onRemoveClick ? (
        <IconButton
          data-hook={DH.REMOVE_TICKET}
          className={classes.headerButton}
          icon={<DeleteSmall />}
          onClick={onRemoveClick}
        />
      ) : null}
      <div className={classes.infoContainer}>
        <LabelWithValue labelClassName={classes.label} label={t('seatings_sector')} value={sector} />
        <LabelWithValue
          labelClassName={classes.label}
          label={area ? t('seatings_area') : t('seatings_row')}
          value={row}
        />
        {area ? null : <LabelWithValue labelClassName={classes.label} label={t('seatings_seat')} value={seat} />}
      </div>
      <div className={classes.ticketInfo}>
        <Text className={classes.ticketName}>{ticketName}</Text>
        <Text className={classes.ticketPrice}>{ticketPrice}</Text>
        <Text className={classes.smallText}>{fees}</Text>
      </div>
      {onAddClick ? (
        <StatesButton
          className={classes.addButton}
          data-hook={DH.ADD}
          successContent={t('seatings_addedToOrder')}
          idleContent={t('seatings_add')}
          state={successButtonState}
          onClick={handleStatesButtonClick}
          onNotificationEnd={() => setSuccessButtonState(BUTTON_STATES.IDLE)}
        />
      ) : null}
    </div>
  )
}
