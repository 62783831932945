import * as React from 'react'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {Text} from 'wix-ui-tpa/Text'
import {Summary} from '../summary'
import {EventInfo} from '../event-info'
import {SlideInModal} from '../../slide-in-modal'
import {TicketsListContainer} from '../tickets-list-container'
import {Ticket} from '../ticket'
import {SummaryContainer} from '../summary-container'
import {AccessibilityModeButton} from '../accessibility-mode-button'
import {classes} from './tickets.st.css'
import {TicketsProps} from '.'

export const Tickets = ({
  showTickets,
  selectedPlaces,
  showAccessibilityMode,
  onBackClick,
  onRemoveClick,
  onCheckoutClick,
  onTicketMoreInfoClick,
}: TicketsProps) => {
  const {isMobile} = useEnvironment()
  const {t} = useTranslation()
  const desktop = !isMobile

  return (
    <SlideInModal
      backButtonText={showAccessibilityMode ? t('seatings_backToList') : t('seatings_backToMap')}
      show={showTickets}
      onBackClick={onBackClick}
    >
      <EventInfo />
      <Text className={classes.title}>{t('seatings_yourTickets', {count: selectedPlaces.length})}</Text>
      {desktop && !selectedPlaces.length ? (
        <Text className={classes.subtitle}>{t('seatings_tickets_emptyState_description')}</Text>
      ) : null}
      <TicketsListContainer>
        {isMobile && !selectedPlaces.length ? (
          <Text className={classes.mobileEmptyState}>{t('seatings_tickets_emptyState_description')}</Text>
        ) : null}
        {selectedPlaces.map((selectedPlace, index) => (
          <Ticket
            key={selectedPlace.id}
            lastTicket={index === selectedPlaces.length - 1}
            place={selectedPlace}
            onRemoveClick={onRemoveClick}
            onTicketMoreInfoClick={onTicketMoreInfoClick}
          />
        ))}
      </TicketsListContainer>
      {desktop ? <AccessibilityModeButton /> : null}
      <SummaryContainer inTickets={true}>
        <Summary inTickets={true} onCheckoutClick={onCheckoutClick} onBackClick={onBackClick} />
      </SummaryContainer>
    </SlideInModal>
  )
}
