import * as React from 'react'
import {Plan} from '../plan'
import s from './desktop.scss'
import {Sidebar} from './sidebar'
import {DesktopProps} from '.'

export const Desktop = ({
  selectedPlace,
  selectedPlaces,
  plan,
  onSeatClick,
  onRemoveClick,
  onCheckoutClick,
}: DesktopProps) => (
  <div className={s.container}>
    <Sidebar selectedSeats={selectedPlaces} onRemoveClick={onRemoveClick} onCheckoutClick={onCheckoutClick} />
    <div className={s.planContainer}>
      <Plan selectedSeat={selectedPlace} selectedSeats={selectedPlaces} plan={plan} onSeatClick={onSeatClick} />
    </div>
  </div>
)
