import * as React from 'react'
import {Tabs} from 'wix-ui-tpa/Tabs'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Tickets} from '../tickets'
import {TicketDescription} from '../ticket-description'
import {Plan} from '../plan'
import {Footer} from '../footer'
import {AccessibilityMode} from '../accessibility-mode'
import {SelectedPlace} from '../../../../types/seating'
import {classes} from './mobile.st.css'
import {MobileProps} from '.'

export const Mobile = ({
  selectedPlaces,
  selectedPlace,
  plan,
  onRemoveClick,
  onCheckoutClick,
  onSeatClick,
  onAddClick,
  onCloseClick,
  showAccessibilityMode,
  setShowAccessibilityMode,
}: MobileProps) => {
  const [showTickets, setShowTickets] = React.useState(false)
  const [selectedSeatForDescription, setSelectedSeatForDescription] = React.useState<SelectedPlace>(null)
  const {t} = useTranslation()

  return (
    <div className={classes.container}>
      <Tabs
        className={classes.tabs}
        items={[{title: t('seatingMapModal_mapTab_title')}, {title: t('seatingMapModal_listTab_title')}]}
        activeTabIndex={showAccessibilityMode ? 1 : 0}
        onTabClick={() => setShowAccessibilityMode(!showAccessibilityMode)}
      />
      <Tickets
        showAccessibilityMode={showAccessibilityMode}
        selectedPlaces={selectedPlaces}
        showTickets={showTickets}
        onBackClick={() => setShowTickets(false)}
        onRemoveClick={onRemoveClick}
        onCheckoutClick={onCheckoutClick}
        onTicketMoreInfoClick={setSelectedSeatForDescription}
      />
      <TicketDescription
        selectedSeatForDescription={selectedSeatForDescription}
        onClose={() => setSelectedSeatForDescription(null)}
      />
      {showAccessibilityMode ? <AccessibilityMode /> : null}
      <Plan selectedSeat={selectedPlace} selectedSeats={selectedPlaces} plan={plan} onSeatClick={onSeatClick} />
      <Footer
        withoutBreakdown={true}
        selectedSeat={selectedPlace}
        onAddClick={onAddClick}
        onCloseClick={onCloseClick}
        onCheckoutClick={() => setShowTickets(true)}
      />
    </div>
  )
}
