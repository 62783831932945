import React from 'react'
import classNames from 'classnames'
import {Header} from '../common/header'
import {CloseButton} from '../common/close-button'
import {ModalProps} from './interfaces'
import s from './modal.scss'

export const Modal = ({
  children,
  closeModal,
  skin,
  closeButtonAsButton,
  className,
  dataHook,
  title,
  withoutPadding,
}: ModalProps) => {
  const grey = skin === 'grey'
  const withTitle = Boolean(title)

  const getPaddingBottom = () => {
    if (withTitle || withoutPadding || grey) {
      return 0
    }

    return 52
  }

  return (
    <>
      <div className={s.underlay} />
      <div
        className={classNames(s.container, className, {
          [s.grey]: grey,
          [s.noPadding]: withTitle || withoutPadding,
        })}
        style={{paddingBottom: getPaddingBottom()}}
        data-hook={dataHook}
      >
        {withTitle ? (
          <Header title={title} closeButtonAsButton={closeButtonAsButton} closeModal={closeModal} />
        ) : (
          <CloseButton closeButtonAsButton={closeButtonAsButton} closeModal={closeModal} />
        )}
        {children}
      </div>
    </>
  )
}
