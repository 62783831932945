import * as React from 'react'
import classNames from 'classnames'
import {TextButton} from 'wix-ui-tpa/TextButton'
import ChevronRightSmall from 'wix-ui-icons-common/on-stage/ChevronRightSmall'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {TicketInfo} from '../ticket-info'
import {classes} from './ticket.st.css'
import {TicketProps} from '.'

export const Ticket = ({
  place,
  withCheckbox,
  selected,
  lastTicket,
  inDescription,
  onRemoveClick,
  onClick,
  onTicketMoreInfoClick,
}: TicketProps) => {
  const {t} = useTranslation()

  const handleRemove = () => onRemoveClick(place.id, place.ticketId)

  const handleKeyDown: React.KeyboardEventHandler<HTMLLIElement> = event => {
    if (event.key === 'Enter') {
      onClick?.(place)
    }
  }

  return (
    <li
      className={classNames(classes.ticket, {[classes.lastTicket]: lastTicket})}
      onClick={() => onClick?.(place)}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      <div className={classes.color} style={{backgroundColor: place?.color}} />
      <TicketInfo
        selectedSeat={place}
        onRemoveClick={onRemoveClick ? handleRemove : undefined}
        withCheckbox={withCheckbox}
        selected={selected}
      />
      {!inDescription && place.ticketDescription ? (
        <TextButton
          data-hook={DH.MORE_BUTTON}
          className={classes.moreInfo}
          suffixIcon={<ChevronRightSmall />}
          onClick={() => onTicketMoreInfoClick(place)}
        >
          {t('seatings_ticket_moreInfo')}
        </TextButton>
      ) : null}
    </li>
  )
}
