import * as React from 'react'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {Button} from 'wix-ui-tpa/Button'
import {TextButton} from 'wix-ui-tpa/TextButton'
import LockFill from 'wix-ui-icons-common/on-stage/LockFill'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {InnerSummary} from '../inner-summary'
import {classes} from './summary.st.css'
import {SummaryProps} from '.'

export const Summary = ({
  invoice,
  selectedTickets,
  withoutBreakdown,
  inTickets,
  onCheckoutClick,
  onBackClick,
}: SummaryProps) => {
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()

  const getCheckoutText = () => {
    if (!isMobile) {
      return t('seatings_desktopCheckout')
    }

    return inTickets ? t('seatings_continueToCheckout') : t('seatings_checkout', {count: selectedTickets})
  }

  return (
    <div className={classes.container}>
      {selectedTickets || inTickets ? (
        <InnerSummary invoice={invoice} withoutBreakdown={withoutBreakdown} selectedTickets={selectedTickets} />
      ) : (
        t('seatings_selectTickets')
      )}
      {onCheckoutClick ? (
        <Button
          data-hook={inTickets ? DH.CHECKOUT : DH.REVIEW_AND_CHECKOUT}
          fullWidth
          upgrade
          prefixIcon={<LockFill />}
          className={classes.button}
          disabled={!selectedTickets}
          onClick={onCheckoutClick}
        >
          {getCheckoutText()}
        </Button>
      ) : null}
      {isMobile && inTickets ? (
        <TextButton className={classes.textButton} onClick={onBackClick}>
          {t('seatings_backToSelectingTickets')}
        </TextButton>
      ) : null}
    </div>
  )
}
