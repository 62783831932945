import React from 'react'
import classNames from 'classnames'
import {TicketInfo} from '../ticket-info'
import s from './seating-popover.scss'
import {SeatingPopoverProps} from './interfaces'

export const SeatingPopover = ({popoverData}: SeatingPopoverProps) => {
  if (!popoverData?.info) {
    return null
  }

  const {boundingClientRect, containerBoundingClientRect} = popoverData
  const hasTaxes = Boolean(popoverData.info.fees)
  const width = 237
  const height = hasTaxes ? 150 : 132
  const spaceBetweenTooltipAndSeat = 10
  const shouldOpenBottom = boundingClientRect.top - height - spaceBetweenTooltipAndSeat < 10
  const top = shouldOpenBottom
    ? boundingClientRect.bottom + spaceBetweenTooltipAndSeat - containerBoundingClientRect.top
    : boundingClientRect.top - height - spaceBetweenTooltipAndSeat - containerBoundingClientRect.top

  return (
    <div
      className={classNames(s.container, {[s.bottom]: shouldOpenBottom})}
      style={{
        width,
        height,
        top,
        left: (boundingClientRect.left + boundingClientRect.right) / 2 - containerBoundingClientRect.left - width / 2,
        position: 'absolute',
        backgroundColor: 'white',
      }}
    >
      <TicketInfo selectedSeat={popoverData.info} />
    </div>
  )
}
