import {withTranslation} from '@wix/yoshi-flow-editor'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {PlanOwnProps, PlanRuntimeProps} from './interfaces'
import {Plan as Presentation} from './plan'

const mapRuntime = ({
  state: {
    seating: {plan},
    tickets,
    event,
  },
}: DetailsPageAppProps): PlanRuntimeProps => ({
  plan,
  tickets,
  event,
})

export const Plan = connect<PlanOwnProps, PlanRuntimeProps>(mapRuntime)(withTranslation()(Presentation))

export * from './interfaces'
